.App__BlogCom{
    width: 100%;
    margin: 2rem 0;

}
.App__BlogCom-heading{
    width: 90%;
}
.App__BlogCom-heading h3{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 1.7rem;
line-height: 33px;

color: #CAC9C9;

}
.App__BlogCom-img{
    width: 100%;
    /* background: #000; */
}
.App__BlogCom-img img{
    width: 100%;
    height: 370px;
    /* object-fit: cover; */
    display: flex;
    justify-content: flex-start;
}

@media screen  and (min-width:1750px){
    .App__BlogCom-img img{
       height: 420px;
    }
}
@media screen  and (min-width:2050px){
    .App__BlogCom-img img{
       height: 480px;
    }
}
@media screen  and (max-width:1000px){
    .App__BlogCom-img img{
       height: 430px;
    }
}
@media screen and (max-width :750px) {
    .App__BlogCom-heading{
        width: 100%;
    }
}
@media screen  and (max-width:500px){
    .App__BlogCom-img img{
       height: 340px;
    }
}
