.App__questionPage-hero{
    background-image: url(../../assets/img/SignUpFacility.png);
    height: 400px;
    width: 100%;
    min-height: 100%;
    background-size: cover;
    color: var(--white-color);    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
}
.joined-btn{
    padding: 0.8rem 4rem;
    background: #05B1F3;
    border: 2px solid #FFFFFF;
    margin: 1.1rem 0;
    cursor: pointer;
}
.joined-btn a{
    color: var(--white-color);
}

.App___form{
    background: var(--blue);
    width: 100%;
    padding: 3rem 2rem;
}

.App___form h1 , h4{
    color: var(--white-color);
}
label{
    color: var(--white-color);
}


.form-wrapper {
    margin-top: 2rem;
    margin-bottom: 3rem;
}
@media screen and (max-width:600px) {
    .working-hours-align{
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;
    }
}