.patient-detail-box{
    background: #E6EFF9;
    padding: 5rem 7rem;
}
.patient-detail-heading{
    text-align: center;

}
.patient-detail-box{
    margin: 2px 0;
    
}
.patient-detail-boxing{
    
    border-bottom: 1px solid rgba(140, 143, 165, 0.38);
}
.patient-box-label{
    display: flex;
    /* justify-content: center; */
    /* align-items: flex-end; */
    
}
.patient-box-label img{
    width: 22px;
    height: 22px;
}
.patient-box-label h2{
    color: #8C8FA5;
    padding: 0;
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
}
.patient-detail-box h3{
    margin-top: 10px;
    font-size: 20px;
    color: #8C8FA5;
    font-weight: 400;

}
.patient-age{
    display: flex;
    margin: 30px 0;
}
.patient-age img{
    height: 23px ;
    width: 30px;
}
.patient-age h2{
    color: #8C8FA5;
    padding: 0;
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
}
.age-slector{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;

}

.age-box{
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 13px;
    cursor: pointer;
    transition: all 0.3s;
}
.age-box:hover{
    background: #05B1F3;
    color: white;
}
.age-box p{
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 500;

}
.patient-phone-box{
    margin: 20px 0;
    border-bottom: 1px solid rgba(140, 143, 165, 0.38);
}
.patient-phone{
    display: flex;
}
.patient-phone h2{
    color: #8C8FA5;
    padding: 0;
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
}
.patient-phone img{
    height: 22px ;
    width: 27px;
}

.selct-code-box input ,.selct-code-box select{
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0.3rem 1rem;
    color:#8C8FA5;
    font-size: 18px;
}
.gender-box{
    display: flex;
    gap: 20px;
}
.gender-flex{
    display: flex;
    align-items: center;
    margin-top: 0.7rem;
}
.gender-flex img{
    width: 23px ;
    height: 23px;
    cursor: pointer;
}
.gender-flex p{
    margin: 0;
    margin-left: 6px;
}
.problems-box{
    width: 100%;
}
.problems-box textarea{
    width: 100%;
    height: 180px;
    padding: 15px;
    border: 1px solid rgba(140, 143, 165, 0.5);
    background-color: transparent;
    outline: none;
    border-radius: 5px;
    resize: none;
    font-size: 19px;
}
.btn-box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 2rem 0;
}
.btn-patient{
    border: none;
    outline: none;
    color: white;
    border-radius: 4px;
    background: #05B1F3;
    width: 50%;
    padding: 0.4rem 0.8rem;
}
@media screen and (min-width : 1500px) {
    .age-box{
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 180px;
        border-radius: 13px;
        cursor: pointer;
        transition: all 0.3s;
    }   
    .age-box p{
        margin: 0;
        padding: 0;
        font-size: 27px;
        font-weight: 500;
    
    }
  
    .btn-patient{
        border: none;
        outline: none;
        color: white;
        border-radius: 4px;
        background: #05B1F3;
        width: 50%;
        padding: 1rem 1.3rem;
        font-size: 23px;
    }
}
@media screen and (max-width:700px) {
    .patient-detail-box{
        background: #E6EFF9;
        padding: 5rem 4rem;
    }
    .btn-patient{
    
        border: none;
        outline: none;
        color: white;
        border-radius: 4px;
        background: #05B1F3;
        width: 100%;
        padding: 0.4rem 0.8rem;
    }
}
@media screen and (max-width:500px) {
    .patient-detail-box{
        background: #E6EFF9;
        padding: 5rem 2rem;
    }
    .patient-detail-box{
        background: #E6EFF9;
        padding: 5rem 4rem;
    }
    .btn-patient{
    
        border: none;
        outline: none;
        color: white;
        border-radius: 4px;
        background: #05B1F3;
        width: 100%;
        padding: 0.4rem 0.8rem;
    }
    .age-box{
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        border-radius: 13px;
        cursor: pointer;
        transition: all 0.3s;
        font-size: 16px;
    }
}