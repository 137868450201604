* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-base);
  /* font-weight: 900; */
}

.app {
  width: 100%;
  /* height: 100vh; */
}

.main-heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 117px;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.app-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: var(--font-base);
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 29px;
}

.sm-text {
  font-family: var(--font-base);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 29px;
}

.heading {
  font-family: var(--font-base);
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;

}

.md-heading {
  font-family: var(--font-base);
  font-style: normal;
  /* font-size: 96px; */
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 1.5rem;

}

.app-grid-two-column {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.xdd-heading {
  font-family: var(--font-base);
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;

  /* font-size: 96px; */
  /* color: white; */
  /* line-height: 117px; */
}

@media screen and (max-width:750px) {
  .app-grid-two-column {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
}

@media screen and (max-width:540px) {
  .text {
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 25px;
  }

  .sm-text {

    font-weight: 450;
    font-size: 0.6rem;
    line-height: 1.2;
  }

  .md-heading {
    font-family: var(--font-base);
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 1.5rem;

  }

  .xdd-heading {
    font-family: var(--font-base);
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    /* font-size: 96px; */
    /* color: white; */
    /* line-height: 117px; */
  } 

  .heading {
    font-family: var(--font-base);
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;

  }
}
@media screen and (max-width:999px) {
  .App__sec{
     margin-top: 6rem;
  }
}
@media screen and (max-width:380px) {
  .xdd-heading{
     font-size: 1.5rem;
  }
}