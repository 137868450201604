.App_hero {
  background: url(../../assets/img/homeBanner.png);
  width: 100%;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  padding: 1rem 3rem;
  align-items: center;
  justify-content: space-between;
  /* color: var(--white-color); */
}
.search__tags-hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}
.App__search-bottom h3 {
  font-size: 1rem;
  font-weight: 600;
}
.tags-card-hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1.3rem;
  border-radius: 6px;
  border: none;
  outline: none;
  background: rgba(88, 206, 252, 0.75);
}
.tags-card-hero p {
  margin: 0;
  font-size: 0.9rem;
  color: white;
  font-weight: 400;
}
.tags-card-hero img {
  width: 8px;
  height: 8px;
  margin-left: 0.7rem;
}
.hero__section {
  margin: 1rem 0;
}
.hero__section select {
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0.7rem;
}
.hero-form-box h2 {
  color: white;
  text-align: right;
  font-size: 1.5rem;
}
.hero-form {
  background: rgba(217, 217, 217, 0.1);
  box-shadow: inset 39.1333px -39.1333px 39.1333px rgba(165, 165, 165, 0.1),
    inset -39.1333px 39.1333px 39.1333px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(78.2667px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  padding: 1rem;
}
.hero-form-box img {
  height: 400px;
  width: auto;
  object-fit: cover;
}
.label-form-hero {
  background: #000;
}
.location-hero {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.location-hero a {
  margin: 0;
  color: white;
  margin-left: 0.3rem;
}
.location-hero img {
  width: 20px;
  height: 30px;
}
.hero-form-item-input {
  margin: 1rem 0;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem;

  border-radius: 5px;
}
.App-hero-left {
  position: relative;
  height: 27rem;
}
.hero-form-item-input input {
  border: none;
  outline: none;
  background: transparent;
  width: 60%;
}
.search-facalty button {
  width: 100%;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  background: #05b1f3;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
}
.App_hero h1 {
  color: white;
}

.img-one {
  background: url(../../assets/img/home.png);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 3rem 2rem;

  color: var(--black-color);
}

.img-two {
  background: url(../../assets/img/about.png);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 3rem 2rem;

  color: var(--white-color);
}

.img-three {
  background: url(../../assets/img/about.png);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 3rem 2rem;
  align-items: center;
  color: var(--white-color);
}

.carousel-item img {
  height: 500px;
}

.Home__features {
  margin: 4rem 0;
  /* padding: 1.5rem; */
}

.Home__features h1 {
  text-align: center;
}

/* Home-Article */
.Home-Article {
  width: 100%;
  padding: 2rem;
}

.card-img-home img {
  height: 200px;
  width: 100%;
}

.article-home-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

/* .article-card-box{
    width: 40%;
} */
.card-content a {
  padding-bottom: 0.3rem;
  text-decoration: underline;
}

.card-content h3 {
  color: var(--gray-primary-color);
  font-size: 1.2rem;
  margin-top: 0.3rem;
}

/* Home__booking-doctors */
.Home__booking-doctors {
  background: #0c181d;

  padding: 3rem 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Booking_appointement-form {
  background-color: var(--white-color);
  width: 100%;
  padding: 2rem;
}

.Booking_appointement-form h2 {
  text-align: center;
  color: var(--blue);
}

.Booking_appointement-form h3 {
  text-align: center;
  font-weight: 600;
  font-size: 1.4rem;
}

.form--booking {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  align-items: center;
  width: 100%;
}

.form-booking-item {
  background: rgba(88, 206, 252, 0.08);
  padding: 1rem;
  margin: 1rem;
  width: 100%;
}

.form-booking-item select {
  border: none;
  outline: none;
  background: transparent;
  color: var(--gray-primary-color);
  width: 100%;
}

.form-booking-item img {
  width: 15px;
  height: 15px;
}

.form-booking-item input {
  border: none;
  outline: none;
  background: transparent;
  color: var(--gray-primary-color);
  margin-left: 0.3rem;
}

.form-booking-item input::placeholder {
  color: var(--gray-primary-color);
}

.form-home-btn {
  padding: 1rem;
}

.form-home-btn button {
  width: 100%;
  border: none;
  outline: none;
  background: var(--blue);
  color: var(--white-color);
  font-weight: 600;
  font-size: 1.2rem;
  padding: 0.7rem;
}

/* Home-top-doctors */
.Home-top-doctors {
  padding: 2rem;
  width: 100%;
}

.Home-top-doctors h1 {
  text-align: center;
  color: var(--white-color);
}

.top-doctors-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 3rem 0;
}

.top-doc-card {
  width: 400px;
}

.top-doc-card-img {
  /*background: url(../../../public/assets/img/offerCardBanner.png);*/
  background: url(../../../public/img/offerCardBanner.png);
  background-position: center;
  background-size: cover;
  height: 250px;
  width: 100%;
  position: relative;
}

.top-doc-card-img img {
  width: 20px;
  height: 20px;
}

.top-doc-para {
  position: absolute;
  top: 8px;
  left: 8px;

  /* padding: 0.1rem 0.3rem; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.top-doc-para p {
  background: #e74040;

  color: var(--white-color);
  font-size: 0.8rem;
  padding: 0.1rem 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  /* line-height: 0; */
}

.top-doc-icons {
  position: absolute;
  bottom: 2px;
  left: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.top-doc-icons button {
  border: none;
  outline: none;
  background-color: var(--white-color);
  border-radius: 50%;
  padding: 0.3rem;
  margin: 0.2rem;
}

.top-doc-card-content {
  background: var(--white-color);
  padding: 0.5rem;
}

.top-doc-heading {
  display: flex;
  justify-content: space-between;
}

.top-doc-heading h3 {
  font-size: 1rem;
  color: var(--blue);
  font-weight: 600;
}

.rating {
  background: #2d4059;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.4rem;
  border-radius: 14px;
  color: var(--white-color);
  font-size: 0.7rem;
}

.rating img {
  height: 11px;
  width: 11px;
  margin-right: 0.2rem;
}
.offer_detail-card h3 {
  color: #2d4059;
  font-size: 17px;
}
.offer_detail-card p {
  font-size: 17px;
  color: #737373;
  letter-spacing: 0.2px;
  max-width: 80%;
}
.dnld_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dnld_wrap p {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  margin-left: 0.2rem;
}
.dolrs_wrap {
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
}
.dolrs_wrap span:first-child {
  color: #737373;
}
.dolrs_wrap span:last-child {
  color: #ffab71;
}
.offer_btn-card {
  margin: 0.7rem 0;
}
.offer_btn-card button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0.4rem 1.7rem;
  color: #96bb7c;
  background: none;
  border-radius: 19px;
  border: 1px solid #96bb7c;
}
.offer_btn-card button img {
  margin-left: 0.4rem;
}
.offers_video-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.line {
  width: 17%;
  height: 6px;
  background: #e74040;
  border-radius: 19px;
}
.video-wrap-left {
  width: 30%;
}
.video-wrap-left h2 {
  color: white;
  margin: 2rem 0;
}
.video-wrap-left p {
  color: white;
  font-size: 15px;
  max-width: 90%;
}
.video-wrap-btn button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0.4rem 1.7rem;
  color: #96bb7c;
  background: none;
}
.video-wrap-btn button img {
  margin-left: 0.4rem;
}
.video-wrap-right {
  width: 300px;
  height: 220px;
}
.video-wrap-right img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1050px) {
  .video-wrap-left {
    width: 40%;
    margin-right: 1.2rem;
  }
}
@media screen and (max-width: 700px) {
  .offers_video-wrap {
    flex-direction: column;
  }
  .video-wrap-left {
    width: 90%;
    margin-bottom: 1.2rem;
  }
}
.top-doc-contact {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  /* justify-content: center; */
}

.top-doc-contact img {
  height: 12px;
  width: 12px;
  margin: 0;
  padding: 0;
}

.top-doc-contact p {
  font-size: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.2rem;
  margin: 0;
}
.offer_second-heading h3 {
  color: white;
}
.offer_second-heading p {
  color: white;
  font-size: 10px;
}
/*------------------------------ Deal-section */
.top_deal-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-deal {
  background: #0c181d;
  padding: 1rem;
  position: relative;
  top: -2rem;
  width: 94%;
  /* margin: 0 1.2rem; */
  border-radius: 5px;
}

.deal-card {
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
}

.deal-card img {
  width: 45px;
  height: 45px;
}

.hot-card {
  width: 9rem;
}

.hot-card img {
  height: 130px;
  width: 100%;
}

.top-deal-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.top-deal-content p {
  color: white;
  font-size: 22px;
  max-width: 50%;
  background: green;
}

.hot-deal-options {
  width: 45rem;
}

.hot-deal-options select {
  border: none;
  outline: none;
  padding: 0.5rem;
  /* border-radius: 2px; */
  color: black;
  width: 100%;
}

.top-deal-content button {
  /* width: 100%; */
  border: none;
  outline: none;
  background: var(--blue);
  color: var(--white-color);
  font-weight: 500;
  font-size: 1rem;
  padding: 0.3rem 1.4rem;
}

.wrapper {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* background: red; */
  height: auto;
}

.wrapper .static-txt {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
}

.wrapper .dynamic-txts {
  margin-left: -15px;
  height: 90px;
  /* line-height: 20px; */
  overflow: hidden;
  position: relative;
  top: 8px;
}

.hot-deal-options h3 {
  color: white;
}

.dynamic-txts li {
  /* height: 100px; */
  list-style: none;
  color: #fc6d6d;
  font-size: 30px;
  font-weight: 500;
  position: relative;
  top: 0;
  animation: slide 12s steps(4) infinite;
}

@keyframes slide {
  100% {
    top: -360px;
  }
}

.dynamic-txts li span {
  position: relative;
  /* margin-bottom: 4rem; */
  /* margin: 3px 0; */
  top: 0;

  line-height: 90px;
}

.dynamic-txts li span::after {
  content: "";
  position: absolute;
  top: -0.7rem;
  left: 0;
  height: 60px;
  width: 100%;
  background: #0c181d;
  border-left: 2px solid #fc6d6d;
  animation: typing 3s steps(20) infinite;
}

@keyframes typing {
  40%,
  60% {
    left: calc(100% + 50px);
  }

  100% {
    left: 0;
  }
}

@media screen and (max-width: 550px) {
  .wrapper .dynamic-txts {
    margin-left: -15px;
    height: 90px;
    /* line-height: 20px; */
    overflow: hidden;
    position: relative;
    top: 8px;
    left: -13px;
  }

  .dynamic-txts li {
    /* height: 100px; */
    list-style: none;
    color: #fc6d6d;
    font-size: 30px;
    font-weight: 500;
    position: relative;
    top: 0;
    animation: slide 12s steps(4) infinite;
  }
}

@media screen and (max-width: 850px) {
  .top-deal-content {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 975px) {
  .form--booking {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  .form-booking-align input {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .top-doc-card {
    width: 100%;
  }

  .top-doc-card-link {
    width: 100%;
  }

  .Home-top-doctors {
    padding: 2rem 0;
  }

  .Booking_appointement-form {
    background-color: var(--white-color);
    width: 100%;
    padding: 1rem 0;
  }

  .Home__booking-doctors {
    padding: 3rem 1.5rem;
  }
  .music img {
    width: 150px;
    height: 40px;
  }
}
@media screen and (max-width: 490px) {
  .wrapper .static-txt {
    color: #fff;
    font-size: 25px;
    font-weight: 400;
  }
  .hero-left-wrap {
    width: 100%;
  }
  .App-hero-left {
    width: 90%;
  }
  .tags-card-hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0.8rem;
    border-radius: 6px;
    border: none;
    outline: none;
    background: rgba(88, 206, 252, 0.75);
  }
  .tags-card-hero p {
    margin: 0;
    font-size: 0.6rem;
    color: white;
    font-weight: 400;
  }
  .tags-card-hero img {
    width: 7px;
    height: 7px;
    margin-left: 0.7rem;
  }
}

@media screen and (max-width: 350px) {
  .form-booking-item {
    background: rgba(88, 206, 252, 0.08);
    padding: 0.6rem;
    margin: 0.8rem;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .carousel-item img {
    height: 300px;
  }

  .hot-card {
    width: 5rem;
  }

  .hot-card img {
    height: 90px;
    width: 100%;
  }
}

@media screen and (max-width: 410px) {
  .form-home-btn button {
    width: 100%;
    border: none;
    outline: none;
    background: var(--blue);
    color: var(--white-color);
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0.7rem;
  }
}

@media screen and (max-width: 999px) {
  .App__sec {
    margin-top: 6rem;
  }
}
@media screen and (max-width: 896px) {
  .App_hero {
    height: auto;
    width: 100%;
  }
}
@media screen and (max-width: 895px) {
  .App-hero-left {
    position: relative;
    display: flex;
    /* height: 27rem; */
  }
  .App_hero {
    width: 100%;
  }
  .App-hero-left h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 810px) {
  .App_hero {
    flex-direction: column;
    width: 100%;
  }
  .hero-form {
    margin-bottom: 2rem;
  }
  .location-hero {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 2px;
    right: 0;
  }
  .hero-left-wrap {
    width: 100%;
  }
  .App-hero-left {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  .App-hero-left {
    position: relative;
    display: flex;
    height: 20rem;
    width: 90%;
  }
  .App_hero {
    width: 100%;
  }
  .App-hero-left h1 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 400px) {
  .App_hero {
    padding: 1rem 1.5rem;
  }
  .App-hero-left h1 {
    font-size: 1.3rem;
  }
  .hero-form-box h2 {
    color: white;
    text-align: right;
    font-size: 1.2rem;
  }
  .location-hero a {
    margin: 0;
    color: white;
    margin-left: 0.3rem;
    font-size: 0.7rem;
  }
  .location-hero img {
    width: 15px;
    height: 22px;
  }
}
@media screen and (min-width: 2000px) {
  .App-hero-left {
    position: relative;
    display: flex;
    /* height: 27rem; */
  }
  .App_hero {
    height: 900px;
    width: 100%;
  }
}
@media screen and (min-width: 2500px) {
  .App-hero-left {
    position: relative;
    display: flex;
    /* height: 27rem; */
  }
  .App_hero {
    height: 1100px;
    width: 100%;
  }
}
