.app__footer {
    background: rgb(4, 4, 4);
    background: linear-gradient(0deg, rgba(4, 4, 4, 1) 0%, rgba(80, 187, 230, 1) 100%);
    /* transform: rotate(-180deg); */
    width: 100%;

    padding: 3rem;
    
    padding-bottom: 5rem;
}

.app__footer-wrap-top {
    width: 100%;
    
}

.app__footer-wrap-left-define {
    width: 80%;
    color: white;
}
.app__footer-wrap-left-define p{
    font-size: 1rem;
}
.app__footer-heading {
    background: var(--black-color);
    padding: 1.5rem;
    width: 100%;
    /* margin: 1rem 2rem; */
}

/* .app__footer-heading-left {
    width: 60%;
} */

.app__footer-heading-left h2 {
    font-family: var(--font-base);
    font-style: normal;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 60px;
    color: var(--white-color);
}

.app__footer-heading-right{
    width: 100%;
}

.app__footer-subscribe {
    border: 1px solid var(--white-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 8px 20px;
    background-color: var(--black-color);
  
    box-sizing: border-box;
}

.app__footer-subscribe input {
    border: none;
    outline: none;
    background-color: var(--black-color);
    margin: 0 5px;


    color: #CAC9C9;
}

.app__footer-subscribe button {
    background: #05B1F3;
    box-shadow: 0px 4px 4px #000000;
    border-radius: 8px;
    padding: 3px 20px;
    border: none;
    outline: none;
    cursor: pointer;
}

.app__footer-wrap-top {
    margin-top: 2.8rem;
}



.app__footer-wrap-left-list{
    color: var(--white-color);
}
.app__footer-item-wrap{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.footer-wrap-item a{
    color: var(--white-color);
    font-size: 1rem;
    
}
.footer-wrap-item li{
    margin-top: 12px;
}
.footer-wrap-item img{
    width: 8px;
    height: 12px;
}
.app__footer-social-icons{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* margin-top: 2rem; */
   
}
.app__footer-social-icons li{
    background: var(--black-color);
    border-radius: 50%;
    padding: 0.6rem;
    margin: 0 6px;
}
.app__footer-social-icons svg{
    width: 30px;
    height: 30px;
    color: var(--white-color);
    transition: all 0.3s ease-in-out;
}
.app__footer-social-icons svg:hover{
    color: var(--blue);
}
.app__footer-connect{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
   }
   .social-btns{
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
   }
   .app-btn {
     /* width: 45%; */
     max-width: 160px;
     color: #fff;
     margin: 20px 10px;
     text-align: left;
     border-radius: 5px;
     text-decoration: none;
     font-family: "Lucida Grande", sans-serif;
     font-size: 10px;
     text-transform: uppercase;
     background: #000;
     padding: 0.5rem 0.7rem;
   
   
    }
    .app-btn img{
        width: 30px;
        height: 30px;
    }
    .app-btn p{
        margin:  0 0.2rem;
    }
   .big-txt {
    font-size: 17px;
    text-transform: capitalize;
   }
 
   .app-btn .blu {
    background-color: #101010;
    transition: background-color 0.25s linear;
    
   }
   .app-btn:hover{
    color: white;
   }
   

@media screen and (max-width:940px){
  .app__footer-heading{
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2 ,1fr);
  } 
  .app__footer-heading-left{
    text-align: center;
    width: 100%;
}  
}
@media screen and (max-width:1070px){
  
    /* .app__footer-heading-left h2 {
        font-family: var(--font-base);
        text-align: center;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 60px;
        color: var(--white-color);
    } */
    .app__footer-heading-left{
        /* text-align: center; */
        width: 100%;
    }
}

@media screen and (max-width:750px){

    .app__footer-heading-left h2 {
        font-family: var(--font-base);
        /* text-align: center; */
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 40px;
        color: var(--white-color);
    }
    .app__footer{
        padding: 2rem;
    }
}
@media screen and (max-width:540px) {
  
    .footer-wrap-item img{
        width: 6px;
        height: 10px;
    }
     .app__footer-subscribe{
        padding: 5px 10px;
     }
    .app__footer-subscribe input{
        width: 60%;

    }
    .app__footer-subscribe img{
        display: none;
    }
    .app__footer-social-icons{
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-top: 2rem; */
       
    }
    .app__footer-social-icons li{
        background: var(--black-color);
        border-radius: 50%;
        padding: 0.5rem;
        margin: 0 5px;
    }
    .app__footer-social-icons svg{
        width: 25px;
        height: 25px;
        
    }
}
@media screen and (max-width:500px) {
    .app__footer-wrap-left-define {
        color: var(--white-color);
        width: 100%;
    }
    .footer-wrap-item img{
        width: 6px;
        height: 10px;
    }
    .footer-wrap-item a{
        font-size: 0.7rem;
    }
    .app__footer{
    padding-bottom: 8rem;
    }
    .app__footer-wrap-left-define p{
        font-size: 0.7rem;
    }
    .app__footer-subscribe button {
        background: #05B1F3;
        box-shadow: 0px 4px 4px #000000;
        border-radius: 8px;
        padding: 8px 20px;
        border: none;
        outline: none;
        cursor: pointer;
    }
}
@media screen and (max-width:400px){
    .app__footer-item-wrap{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2 , 1fr);
    }
    .app__footer-wrap-left-define{
        grid-row: 1/300;
    }
}