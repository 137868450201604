@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion_wrap{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-wrap: wrap;
    gap: 1rem;
}
.accordionn {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #05B1F3;
    border-radius: 7px 7px 0px 0px;
  color: #ffffff;
  cursor: pointer;
  padding: 0.4rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  /* transition: background-color 0.6s ease; */
  width: 230px;
}
.accordionn p{
    margin: 0;
    padding: 0;
}
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordionn:hover,
.activee {
    background: #FF0000;
    box-shadow: 0px 4px 4px #FFFFFF, 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Style the accordion content title */
.accordion__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: auto;
  transition: max-height 0.6s ease;
  width: 230px;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
@media screen and (max-width:1064px) {
    .accordion {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #05B1F3;
        border-radius: 7px 7px 0px 0px;
      color: #ffffff;
      cursor: pointer;
      padding: 0.4rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      /* transition: background-color 0.6s ease; */
      width: 350px;
    } 
    .accordion__content {
        background-color: white;
        overflow: auto;
        transition: max-height 0.6s ease;
        width: 350px;
      }  
}
@media screen and (max-width:946px) {
    .accordion {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #05B1F3;
        border-radius: 7px 7px 0px 0px;
      color: #ffffff;
      cursor: pointer;
      padding: 0.4rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      /* transition: background-color 0.6s ease; */
      width: 100%;
    } 
    .accordion__content {
        background-color: white;
        overflow: auto;
        transition: max-height 0.6s ease;
        width: 100%;
      }  
}