@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root{
  --bg-1:  #E5E5E5;
  --black-color :#000000;
  --white-color :#FFFFFF;
  --gray-primary-color: #CAC9C9;
  --gray-secondary-color : #C4C4C4;
  --gray-color : '#D9D9D9';
  --red-color : #FF1A1A;
  --blue-fb-color: #699BF7;
  --blue-twitter-color :  #55ACEE;
  --blue-secondary-color :#50BBE6;
  --blue: #05B1F3;
  --primary-blue-color : #58CEFC;


  --font-base :  Montserrat;

}

.gradient-color{
  background:  linear-gradient  (180deg, #040404 0%, #50BBE6 0%, #50BBE6 0%, #000000 0.01%, #09161B 0.02%, rgba(0, 0, 0, 0.865335) 3.65%, #50BBE6 78.65%, #50BBE6 97.4%, rgba(80, 187, 230, 0) 100%);
}
.second-gradient-color{
  background: rgb(4,4,4);
background: linear-gradient(180deg, rgba(4,4,4,1) 0%, rgba(80,187,230,1) 100%);
}