.faq-page {
  background: rgba(238, 234, 253, 1);
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10vh;
  padding-bottom: 10vh;

  h3 {
    color: rgba(20, 20, 32, 1);
    margin-bottom: 4vh;
  }

  h1 {
    color: rgba(71, 85, 105, 1);
    margin-bottom: 10vh;
  }

  svg {
    min-width: 40px;
  }
}

.container {
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  >div {
    width: 50%;

    &:nth-of-type(2) {
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media only screen and (max-width: 768px) {
      width: 100%;

      &:nth-of-type(2) {
        display: none;
      }
    }

  }
}

.faq {
  background: rgba(101, 71, 237, 1);
  color: white;
  height: 9vh;
  transition: 0.2s;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 10vh;
  padding-left: 2.5%;
  padding-right: 2.5%;
  position: relative;

  @media only screen and (max-width: 768px) {
    height: 9.5vh;
    margin-bottom: 5vh;
  }



  h4 {
    padding-top: 2vh;
    padding-bottom: 2vh;


  }

  >div {
    padding-top: 0vh;
  }


}

.faq-header {
  display: flex;
  justify-content: space-between;

  svg {
    display: block;
    margin-top: auto;
    margin-bottom: auto
  }
}

.faq-open {
  @extend .faq;
  height: 28vh;
  transition: 0.2s;
  background: rgba(101, 71, 237, 1);

  @media only screen and (max-width: 768px) {
    height: 40vh;
  }
}

.card-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.faq-card {
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding-top: 4vh;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 4vh;
  width: 31%;
  display: flex;
  color: black !important;
  justify-content: space-evenly;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 3vh;
  }

  h4 {
    color: black;
  }

  h4 {
    font-size: 1rem;
    margin-bottom: 1vh;
  }

  p {
    font-size: 0.8rem;
  }

  svg {
    min-width: 40px;
  }

  h4 {
    margin-bottom: 4vh;
  }
}