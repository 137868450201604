.App__expert-hero{
    background :url(../../assets/img/expertResult.png);
    width: 100%;
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    display: flex;
    padding: 3rem;
    align-items: center;
}

.App__expertPage-wrapper{
    margin: 3rem 0;
    padding: 0 2rem;
}

.App__booking-facility-title p{
    margin: 0 0.4rem;
}

.App__doctor-details-item{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    margin-top: 4rem;
}
.App__doctor-detail-img img{
    width: 220px;
    height: 220px;
}

.App__doctor-detail-content-wrap{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.App__doctor-detail-content{
    width: 60%;
    padding: 1rem;
}
.App__doctor-detail-content a {
    color: var(--black-color);
}

.App__doctor-detail-content p:last-child{
    margin-top: 0.5rem;
}

.App__doctor-detail-content-btn img{
     width: 30px;
     height: 30px;
     margin-right: 0.3rem;
}
.App__doctor-detail-content-btn a{
    color: #4ECB71;
    font-style: normal;
font-weight: 700;
font-size: 1rem;
line-height: 59px;
/* identical to box height */

text-align: center;
}
.App__doctor-detail-content-btn button{
    background: var(--blue);
    color: white;
    border: none;
    outline: none;
    padding: 0.3rem 1rem;
}


.expert-sort{
    background: var( --blue-secondary-color);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}
.sort-left , .sort-right{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}
.sort-left button{
    color: var(--blue);
    background: var(--white-color);
    border: none;
    outline: none;
    padding: 0.4rem 1.3rem;
    font-size: 1.1rem;
    font-weight: 600;
}
.sort-left select , .sort-right select{
    color: var(--blue);
    background: var(--white-color);
    border: none;
    outline: none;
    padding: 0.5rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    margin-left: 1rem;
}
.link__btn a{
    color: var(--white-color);
    font-weight: 400;
    line-height: 10px;
}

@media screen and (max-width : 830px){
    .App__doctor-details-item{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2,1fr);
        gap: 1rem;
        margin-top: 4rem;
    }
    .App__doctor-detail-content{
        width: 100%;
    }    
    .App__doctor-detail-content-btn img{
        width: 30px;
        height: 30px;
        margin-right: 0.3rem;
   }
   .App__doctor-detail-img img{
    width: 300px;
    height: 300px;
}
}
@media screen and (max-width:670px) {
    .sort-left button {
        color: var(--blue);
        background: var(--white-color);
        border: none;
        outline: none;
        padding: 0.4rem 1rem;
        font-size: 1rem;
        font-weight: 600;
    }
    .sort-left select, .sort-right select {
        color: var(--blue);
        background: var(--white-color);
        border: none;
        outline: none;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 600;
        margin-left: 0.5rem;
    }
}
@media screen and (max-width:585px) {
    .expert-sort {
        background: var( --blue-secondary-color);
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .sort-left, .sort-right {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .sort-left{
        width: 100%;
    }
    .sort-left button {
        width: 100%;
        color: var(--blue);
        background: var(--white-color);
        border: none;
        outline: none;
        padding: 0.4rem 1rem;
        font-size: 1rem;
        font-weight: 600;
    }
    .sort-left select, .sort-right select {
        color: var(--blue);
        background: var(--white-color);
        border: none;
        outline: none;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 600;
        margin: 0;
        width: 100%;
    }
    .sort-right{
        width: 100%;
    }
}
@media screen and (max-width : 500px) {
    .App__doctor-detail-img img{
        width: 100%;
        height: 250px;
    }
    .App__doctor-detail-img{
        width: 100%;
    }
}
@media screen and (max-width :450px) {
    .App__doctor-detail-content-btn{
        width: 100%;
    }
    .App__doctor-detail-content-btn button{
        width: 100%;
    }
}
@media screen and (max-width : 380px) {
    .App__doctor-detail-img img{
        width: 220px;
        height: 220px;
    }
    .link__btn{
        width: 100%;
    }
}
@media screen and (min-width:1500px) {
    .App__doctor-detail-img img{
        width: 420px;
        height: 420px;
    }
}