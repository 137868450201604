.App__refralForm{
    width: 100%;
    padding: 7rem 3rem;
}
.Refral-form-heading{
    text-align: center;
    border-bottom:  1px solid rgba(0, 0, 0, 0.3);;
}
.Refral-form{
    margin: 2rem 0;
}
.refral-form-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}
.form-item-input{
    display: flex;
    flex-direction: column;
    width: 100px;

}
.form-item-input input{
    padding: 0.98rem;
    margin-top: 0.4rem;
    border: 1px solid #000000;
}
.Refral-form-item label{
   color: var(--black-color);
   font-weight: 700;
}
.form-input-wrap{
    width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;
    padding: 1rem;
}
.form-input-wrap input{
    border: none;
    outline: none;
    width: 100%;
    margin-left: 0.4rem;
}

.refral-form-second-box{
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    flex-wrap: wrap;
    gap: 1rem;
}

.second-form-item label{
    color: var(--black-color);
    font-weight: 700;
    margin-right: 0.5rem;

}
.second-form-item input{
    padding: 0.98rem;
    margin-top: 0.4rem;
    border: 1px solid #000000;
    width: 40%;
}
.last-form-item{
    margin: 1rem 0;
}
.last-form-refral label{
    color: var(--black-color);
    font-weight: 700;

}
.last-form-refral input{
    padding: 0.98rem;
    margin-top: 0.4rem;
    border: 1px solid #000000;
    width: 100%;
}
.refral-form-textarea {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.refral-form-textarea textarea{
    resize: none;
    width: 100%;
    height: 250px;
    border: 1px solid #000000;
    padding: 0.7rem;
}
.refral-form-btn{
    width: 40%;
    margin: 1.5rem 0;
}
.refral-form-btn button{
    width: 100%;
   
    padding: 1rem;
    background: var(--blue);
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--white-color);
  
}
@media screen  and  (max-width:1139px){
    .second-form-item input{
        padding: 0.98rem;
        margin-top: 0.4rem;
        border: 1px solid #000000;
        width: 100%;
    }
}
@media screen and (max-width:910px) {
    .form-input-wrap{
        width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #000000;
        padding: 1rem;
    }
    .second-form-item{
        display: flex;
        flex-direction: column;
        width: 100%;
        /* background: #000;  */
 }
 .second-form-item input{
     width: 100%;
 
 }
}
@media screen and (max-width:610px) {
    .form-input-wrap{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #000000;
        padding: 1rem;
    }
  .second-form-item{
       display: flex;
       flex-direction: column;
       width: 100%;
       /* background: #000;  */
}
.second-form-item input{
    width: 100%;

}
.refral-form-textarea textarea{
    resize: none;
    width: 100%;
    height: 180px;

}
}
@media screen and (max-width:470px) {
    .big-refral-input{
        width: 100%;
    }
}
@media screen and (max-width : 500px) {
    .refral-form-btn{
        width: 100%;
        margin: 1.5rem 0;
    }
}
@media screen and (max-width:313px) {
    .form-item-input{
        display: flex;
        flex-direction: column;
        width: 100%;
    
    }
}