.App__varify{
    width: 100%;
    /* height: 50vh; */
}

.App__varification-form{
    margin: 3rem 0;
}
.App__varify-form{
    margin : 3rem 0;
}
.varify-formm{
    width: 80%;
    background: #FFFFFF;
border: 1px solid #000000;
}
.varify__form-heading{
    width: 100%;
    border-bottom:1px solid #000000;
    padding: 2rem;
    /* background-color: #000000; */
}
.varif__form-item{
    padding: 2rem;
    width: 100%;

}
.varify-otp-wrap{
    margin-top: 4rem;
}

.varify-otp-input{
    display: flex;
    flex-direction: column;
}

.varify-otp-input label{
    font-style: normal;
font-weight: 400;
font-size: 1.8rem;
margin-bottom: 1.7rem;
/* line-height: 117px; */
}
.varify-otp-input input{
    outline: none;
    background: #E5E5E5;
    border: 1px solid rgba(0, 0, 0, 0.2);
padding: 1rem;
}
.varify-otp-wrap p{
    margin-top: 1.2rem;
}
.varify-otp-wrap button{
    
        padding: 1rem 2rem;
        background-color: var(--blue);
        border: none;
        outline: none;
        cursor: pointer;
        color: var(--white-color);
        width: 100%;
        margin-top: 1.3rem;
}