.email-labor{
    width: 70%;
}

/* @media screen and (min-width: 600px) {
    .email-lobor {
    width: 100%;

      
    }       
} */