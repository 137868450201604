/* App__search */
.App__search{
    background: var(--white-color);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.App__search-container{
    position: relative;
    top: -30px;
    width: 95%;
    border: 1px solid #CAC9C9;
    background-color: var(--white-color);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px #FFFFFF;
    border-radius: 13px;
    padding: 1.3rem 2rem;

}
.App__searchTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}
.search-item{

    width: 44%;
    background: rgba(88, 206, 252, 0.11);
border: 1px solid rgba(0, 0, 0, 0.43);
border-radius: 8px;
 padding: 0.6rem;
 display: flex;
 /* justify-content: space-between; */
 align-items: center;
}
.search-item img{
    width: 17px;
    height: 23px;
    margin-right: 0.5rem;
}
.search-item input ,.search-item select{
    width: 100%;
    border : none;
    outline: none;
    background: transparent;
}
.search-btn button{
    border : none;
    outline: none;
    background: var(--blue);
    padding: 0.8rem;
    border-radius: 8px;
}
.search-btn button img{
    width: 23px;
    height: 23px;
}

.search__tags{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
}
.App__search-bottom h3{
    font-size: 1rem;
    font-weight: 600;
}
.tags-card{
    align-items: center;
    padding: 0.4rem;
    border-radius: 6px;
    border: none;
    outline: none;
    background: rgba(88, 206, 252, 0.12);
}
.tags-card p{
    margin: 0;
    font-size: 0.9rem;
    color: black;
    font-weight: 700;
}
.tags-card img{
    width: 8px;
    height: 8px;
    margin-left: 0.3rem;
}
@media screen and (max-width:780px) {
    .App__searchTop{
        display: block;
        gap: 1rem;
        margin-bottom: 0.4rem;
    }
    .search-item{
        width: 100%;
        margin: 1rem 0;
    }
    .search-btn button{
        width: 100%;
    }
}
@media screen and (max-width : 460px) {
    .search__tags {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
    }
}